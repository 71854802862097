<template>
  <div>
    <top-line-city title="Карта присутствия" image="/BG.png" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pt-4">
          <div class="row">
            <div class="col-12">
              <div class="content content-white">
                <div class="ratio-16x9 mt-4 position-relative">
                  <div
                    id="maps"
                    ref="maps"
                    class="maps"
                    style="height: 500px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ymaps from "ymaps";
import TopLineCity from "@/components/pages/TopLineCity";
import { find, forEach } from "lodash";
import { mapGetters } from "vuex";
// import { forEach } from "lodash";
export default {
  name: "PresenceCard",
  components: { TopLineCity },
  data() {
    return {
      sites: [],
    };
  },
  head: {
    title: function () {
      return {
        inner: this.$route.meta?.title || "Прокат авто",
      };
    },
  },
  computed: {
    ...mapGetters("config", ["getSite", "getActiveCity"]),
    activeSite() {
      if (this.getActiveCity.sites) {
        return (
          find(this.getActiveCity.sites, (site) => site.id === this.getSite) ||
          null
        );
      }
      return null;
    },
    getFilePrefix() {
      return process.env.VUE_APP_FILE_PATH;
    },
  },
  mounted() {
    this.loadMap();
  },
  methods: {
    async loadMap() {
      try {
        const response = await this.$axios.api.maps({});
        this.sites = response.data;
        await this.generateMap();
      } catch (e) {
        console.log(e);
      }
    },
    async generateMap() {
      try {
        const maps = await ymaps.load(
          "https://api-maps.yandex.ru/2.1/?apikey=14791924-ac81-4920-9d77-bac3bcc50308&lang=ru_RU"
        );
        const map = new maps.Map("maps", {
          center: this.activeSite
            ? [this.activeSite.map_lat, this.activeSite.map_lon]
            : [51.528653, 39.830512],
          zoom: this.activeSite ? 8 : 4.5,
          cursor: "pointer",
        });
        const objectManager = new maps.ObjectManager({});
        const markerManager = new maps.ObjectManager({ clusterize: true });
        const markers = [];
        forEach(this.sites, (site) => {
          let content = ``;
          if (site.image) {
            content += `<img src="${this.getFilePrefix}${site.image}" style="max-width: 100%" class="mb-2"/><br/>`;
          }
          content += `<h4>${site.name}</h4>`;
          content += `<b>Адрес:</b> ${site.address}<br/>`;
          content += `<b>Телефон:</b> <a href="tel:${site.phone}">${site.phone}</a><br/>`;
          if (site.phone_2) {
            content += `<b>Телефон:</b> <a href="tel:${site.phone_2}">${site.phone_2}</a><br/>`;
          }
          content += `<a href="/contacts/${site.id}" class="button mt-3">Подробнее</a>`;
          markers.push({
            type: "site",
            id: site.id,
            properties: {
              clusterCaption: site.name,
              balloonContentBody: content,
            },
            geometry: {
              type: "Point", // тип геометрии - точка
              coordinates: [site.map_lat, site.map_lon], // координаты точки
            },
          });
        });
        const { features } = await maps.borders.load("RU", {
          lang: "ru",
          quality: 2,
        });
        const featuresArray = [];
        console.log(features);
        await features.forEach((feature) => {
          feature.id = feature.properties.iso3166;
          if (
            find(
              this.sites,
              (city) => city.region.name_2 === feature.properties.name
            )
          ) {
            feature.options = {
              fillColor: "#FF6600",
              fillOpacity: 0.2,
              strokeWidth: 2,
              strokeColor: "#FF6600",
            };
            featuresArray.push(feature);
          }
        });
        objectManager.add({
          type: "FeatureCollection",
          features: featuresArray,
        });
        markerManager.add({ type: "FeatureCollection", features: markers });

        map.geoObjects.add(objectManager);
        map.geoObjects.add(markerManager);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.maps {
}
</style>
